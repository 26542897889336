export const Partners = (props) => {
  return (
    <div id="partners" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Partners</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <img src={d.icon} width="auto" height="60px"></img>
                  {/* <h3>{d.title}</h3>
                    <p>{d.text}</p> */}
                  <h3></h3>
                </div>
              ))
            : "Loading..."}
        </div>
        <h2></h2>
      </div>
    </div>
  );
};
