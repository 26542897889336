import { Contact } from "./contact";

export const News2 = () => {
  return (
    <div id="news1">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title text-start">
          <h2>It was a boring year in smart home tech, and that's OK</h2>
          <p>
            In the mid-2010s, smart home technology hit the mainstream, thanks
            largely to smart speakers. These clunky columns and pucks had
            embedded robotic voices from Google and Amazon that listened and
            responded to basic queries on simple topics like weather, sports
            scores and movie trivia.
          </p>
          <h3></h3>
          <p>
            Gradually, those capabilities expanded to controlling lights,
            cameras, doorbells and dozens of other devices. Today, millions of
            households have at least one smart speaker. Everything from garage
            doors to toilets are online and operated by app or voice.
          </p>
          <h3></h3>
          <p>
            Looking back on 2021, we saw less accumulation of new smart devices
            and more refining of the core uses for smart home tech and the
            networks that power it. Though this pattern was industrywide, Amazon
            led the pack. Here's a look at the last 12 months across the smart
            home.
          </p>
          <h3></h3>
          <h3>NETWORKING</h3>
          <h3></h3>
          <p>
            The way devices connect is changing, and will continue to change in
            2022. Big industry presences such as Amazon and Google are investing
            in networking and expanding how smart home devices get online.{" "}
          </p>
          <h3></h3>
          <h4>Matter</h4>
          <p>
            Project CHIP (that's Connected Home over IP) rebranded as Matter in
            mid-2021 and kicked off a months-long PR circuit promising big
            things for the smart home. Development was delayed, however, and
            we're now anticipating a 2022 release. Still, the premise is
            intriguing.
          </p>
          <h3></h3>
          <p>
            The goal of this project is to create a unifying protocol across
            major device brands that would allow smart home products to work
            together seamlessly. We've described it before as "a single,
            IP-based, open-source standard that works over Wi-Fi, supports all
            major control platforms, and acts like a universal language that
            smart home devices can use to connect with and understand each
            other."{" "}
          </p>
          <h3></h3>
          <p>
            In 2021, Matter inched closer to reality by gaining buy-in from
            major brands including Apple, Samsung, Google and Amazon. With this
            team of heavy hitters supporting the project, some even promoting it
            on their own sites, Matter will likely change the smart home game
            next year, if all goes as planned.{" "}
          </p>
          <h3></h3>
          <h4>Amazon Sidewalk</h4>
          <h3></h3>
          <p>
            In January, Amazon announced the launch of Amazon Sidewalk. It went
            live in June and is enabled by default on compatible Echo and Ring
            devices.
          </p>
          <h3></h3>
          <p>
            In short, select Echo and Ring devices use Bluetooth LE and 900MHz
            LoRa signals, as well as your home's wireless network, to create a
            sort of mesh system that keeps devices on the edge of your network's
            reach online. This extends to other devices in the neighborhood,
            meaning your neighbor's device could use your network to stay
            online, too.
          </p>
          <h3></h3>
          <p>
            Our resident networking expert Ry Crist says that's not as scary as
            it sounds. Multiple layers of encryption, regular data deletion and
            rolling IDs are just a few of the measures Amazon is instating to
            make Sidewalk more palatable to privacy-minded users.
          </p>
          <h3></h3>
          <p>
            Sidewalk does help your Echo and Ring products stay better connected
            to your smart home, potentially alleviating pesky smart home
            connectivity headaches. That said, the feature is something of a
            background update that doesn't change day-to-day use of your
            devices.
          </p>
          <h3></h3>
          <h4>Ring Alarm Pro</h4>
          <h3></h3>
          <p>
            What's more immediately exciting than Amazon Sidewalk (though that
            admittedly has some interesting potential) is the tech giant's
            security brand Ring, which launched a new generation of its DIY home
            security system.
          </p>
          <h3></h3>
          <p>
            The Ring Alarm Pro isn't just a security system: It works with an
            Eero router to provide mesh Wi-Fi, seamless backup Wi-Fi using
            cellular data (in case of power or internet outages), internet
            security and household usage monitoring features, local storage and
            processing for some of Ring's video devices, Alexa Guard
            compatibility and Z-Wave compatibility. The latter means potential
            for integrating more smart home devices like flood sensors and all
            sorts of other little gadgets.
          </p>
          <h3></h3>
          <p>
            The Ring Alarm Pro is one of the most compelling examples on the
            market of an integrated smart home platform for a reasonable price
            and super easy installation. And with the more niche smart home
            offerings like Z-Wave compatibility piggy-backing on products and
            services with much more mainstream appeal (hello, Wi-Fi, home
            security and backup internet), this product seems poised to smuggle
            a lot more smarts into the average person's home.
          </p>
          <h3></h3>
          <h4>Wi-Fi 6 and 6E</h4>
          <h3></h3>
          <p>
            Speaking of internet, we also saw the rise of the latest Wi-Fi band,
            Wi-Fi 6 and Wi-Fi 6E. This new Wi-Fi standard was released prior to
            2021, but the year brought several new Wi-Fi 6 routers to the
            market.
          </p>
          <h3></h3>
          <p>
            Faster speeds, stronger bands and better range are nothing but good
            news for the internet of things. Problem is, smart home devices
            aren't compatible with this new Wi-Fi yet. For now, it's just a
            handful of the latest smartphones, laptops and routers (we're
            keeping a running list).
          </p>
          <h3></h3>
          <p>
            Besides the Ring Alarm Pro, Wi-Fi 6 compatible smart home devices
            have yet to make a splash, but we expect adoption to spread pretty
            quickly in the coming months. All in all, better Wi-Fi is critical
            to future device generations and keeping a house with dozens of
            connected devices running smoothly.
          </p>
          <h3></h3>
          <h3>NEW SMARTS AND FEATURES</h3>
          <h3></h3>
          <p>
            Big brands updated their devices with hardware and software that
            added significant features and capabilities.
          </p>
          <h3></h3>
          <h4>Google's Sleep Sensing tech</h4>
          <h3></h3>
          <p>
            In March 2021, Google released the second generation of the Nest
            Hub. This 8-inch smart display is a favorite of ours, especially if
            you're looking for a device that doesn't have a camera. However, the
            new model added Sleep Sensing, and that raised the eyebrows of
            anyone concerned with privacy.
          </p>
          <h3></h3>
          <p>
            Sleep Sensing uses Google's miniature radar technology Motion Sense,
            which is powered by Soli, to detect submillimeter movements of the
            person sleeping closest to the display in order to provide sleep
            tracking data and suggestions for better rest. Motion Sense on the
            Nest Hub also enables Quick Gestures, a way to control music
            playback with a wave of your hand, instead of talking to Google
            Assistant or tapping the screen. If we see more smart displays from
            Google in the future, it's likely Soli will be there.
          </p>
          <h3></h3>
          <h4>Amazon's AZ2 chip</h4>
          <h3></h3>
          <p>
            The AZ2 chip Amazon announced in September is 22 times faster than
            the previous chip, AZ1. It also powers voice recognition and facial
            recognition capabilities the company calls Visual ID. You'll find
            Visual ID on Amazon's recently released Echo Show 15 and presumably
            all the Echo devices Amazon releases in the future. New chips don't
            often make the front page, but they're important for supporting new
            technology now and in future upgrades.
          </p>
          <h3></h3>
          <h4>Apple's ultrawideband expansion</h4>
          <h3></h3>
          <p>
            The long awaited Apple AirTags launched in 2021 with the goal of
            helping iOS users find their stuff. It relies on the ultrawideband
            capabilities of Apple's U1 chip, in the iPhone 11 and newer. This
            year's launch of AirTags was the first time we really saw UWB
            technology come to life. It's a significant improvement over
            Bluetooth, which can track items to within 5 meters or so. UWB can
            pinpoint a device to within 5 to 10 centimeters.
          </p>
          <h3></h3>
          <p>
            The HomePod Mini is also equipped with the U1 chip, but Apple didn't
            really turn it on until a 2021 software update. Currently, UWB is
            used in the HomePod Mini to enhance the audio handoff feature
            between UWB-equipped iPhones and the speaker or to locate an AirTag
            with an audible ping. Future devices, integrations and software
            updates could really expand the way the Siri-powered smart home
            locates and understands its pieces.
          </p>
          <h3></h3>
          <h3>HOME SECURITY FOCUS</h3>
          <h3></h3>
          <p>
            There were a handful of new devices, but they're all mostly based on
            well-established product segments. We saw updated versions of things
            we were already buying. Nearly every major smart home device
            category got a new generation of existing products this year, but
            smart home security saw the most significant growth. This is the
            place where we actually did see genuinely new stuff.
          </p>
          <h3></h3>
          <h4>Nest home security</h4>
          <h3></h3>
          <p>
            Google bought Nest, famous for its learning thermostats, nearly
            seven years ago, and has since developed the brand into a serious
            smart home competitor in multiple markets. Most recently, Nest
            rolled out a new line of security devices, including an indoor
            camera, an outdoor camera, a floodlight cam and a wireless video
            doorbell. Expect the company to add a new wired video doorbell to
            replace its existing option (2018's erstwhile Nest Hello) soon.
          </p>
          <h3></h3>
          <p>
            This new product line shows Google really establishing a footprint
            in the DIY home security market -- rejecting the piecemeal approach
            of years past. We liked the cameras generally, though none of them
            blew us away. That said, we're seeing smarter features, like alerts
            that distinguish between people, animals, packages and vehicles,
            become the industry norm. And of course, resolutions are rising and
            prices are falling, slowly but steadily.
          </p>
          <h3></h3>
          <h4>Ring security products</h4>
          <h3></h3>
          <p>
            Ring had a pretty ridiculous year, frankly. It launched nearly 20
            distinct devices, ranging from the excellent Ring Alarm Pro to the
            mediocre Ring Doorbell 4, from the super featured Ring Video
            Doorbell Pro 2 (with built-in radar) to the super affordable Ring
            Video Doorbell Wired (which was selling for about $40 on Black
            Friday).
          </p>
          <h3></h3>
          <p>
            But along with its veritable deluge of new products, Ring also
            battled to rehabilitate its public image after years of playing host
            to racist vitriol on its Neighbors app, fostering unethical and
            chummy relations with police departments, instituting policies that
            degraded community privacy and fending off suspicions following a
            variety of security snafus.
          </p>
          <h3></h3>
          <p>
            How much these controversies really damaged Ring's brand is hard to
            say, considering the company still commands impressive control over
            the market. Aside from optics, did Ring improve? That's a
            complicated question to answer, and we've written about it
            extensively before.
          </p>
          <h3></h3>
          <p>
            The company offers industry-leading options for security and privacy
            (including end-to-end video encryption and local storage and
            processing if you get Ring Alarm Pro); by the same token, Ring is
            also normalizing the transformation of public space into recorded
            space, and facilitating (though now in less direct ways) the sharing
            of footage with police forces.
          </p>
          <h3></h3>
          <p>
            In short, that Ring has had a successful year is inarguable; whether
            or not that's a good thing is up for debate.
          </p>
          <h3></h3>
          <h4>The rest of the field</h4>
          <h3></h3>
          <p>
            Amazon and Google aren't the only companies developing popular home
            security devices. Arlo, Wyze, Abode and plenty of other developers
            continued to refine their product lines. Arlo's security cameras
            continue to be best-in-class for most people, and Wyze continues to
            push prices down across the whole industry.
          </p>
          <h3></h3>
          <p>
            Unlike some others, the home security market remains incredibly
            competitive entering 2022, and not only with regard to security
            cameras and video doorbells. Increasingly, companies like Wyze, Ring
            and others are adding more holistic home security systems, sometimes
            even including professional monitoring subscriptions.
          </p>
          <h3></h3>
          <h3>A FLYOVER YEAR</h3>
          <h3></h3>
          <p>
            What we saw in 2021 wasn't leaps and bounds for the smart home. It
            doesn't need that right now. The smart home became more refined this
            year. Privacy discussions took steps forward. Existing products and
            technologies got better and the things we loved in 2014 are still
            evolving to be their best yet.
          </p>
          <h3></h3>
          <p>
            At the time of this article, we don't have any word on the release
            of Amazon's Astro robot. There's no real-life Ring Always Home Cam
            drone. We're OK with that. The smart home made significant
            improvements in 2021 that might not have grabbed headlines but
            certainly made progress. There's a good chance that means big things
            for 2022.
          </p>

          <h3></h3>
          <p className="font-italic">
            First published on Dec. 15, 2021 at 5:00 a.m. PT.
          </p>
        </div>
      </div>
      <Contact />
    </div>
  );
};
