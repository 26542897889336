import { Contact } from "./contact";

export const News1 = () => {
  return (
    <div id="news1">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title text-start">
          <h2>What Will Smart Homes Look Like 10 Years From Now?</h2>
          <p>
            It’s 6 A.M., and the alarm clock is buzzing earlier than usual. It’s
            not a malfunction: the smart clock scanned your schedule and
            adjusted because you’ve got that big presentation first thing in the
            morning. Your shower automatically turns on and warms to your
            preferred 103°F. The electric car is ready to go, charged by the
            solar panels or wind turbine on your roof. When you get home later,
            there’s an unexpected package waiting, delivered by drone. You open
            it to find cold medicine. Turns out, health sensors embedded in your
            bathroom detected signs of an impending illness and placed an order
            automatically. Good thing you already knocked that presentation out
            of the park.
          </p>
          <h3></h3>
          <p>
            That, at least, is the utopian version of the smart home that exists
            10 years out. Swedish research firm Berg Insight says 63 million
            American homes will qualify as "smart" by 2022, with everything from
            Internet-connected light bulbs to cameras that let us spy on our
            pets from the office (there were nearly 130 million homes in the
            U.S. in total in 2018). But a decade from now, experts say, we’ll
            move from turning the lights on and off with our voices to total
            immersion in the Internet of Things (IoT). Thanks to advancements in
            artificial intelligence, the smartest homes will be able to truly
            learn about their owners or occupants, eventually anticipating their
            needs. Developments in robotics will give us machines that offer a
            helping hand with cleaning, cooking and more. New sensors will keep
            tabs on our well-being. Central to all of this will be the data that
            smart homes collect, analyze and act upon, helping to turn the
            houses of the future from a mere collection of gadgets and
            accessories into truly "smart" homes.
          </p>
          <h3></h3>
          <p>
            All the automated attentiveness will come with a high price tag:
            consumers will spend $123 billion on IoT gear by 2021, according to
            advisory firm ABI Research, a number that’s likely to rise
            thereafter. Aside from Internet-connected televisions, manufacturers
            are putting their R&D and marketing budgets behind home-monitoring
            and security gadgets–they will have 22.6% of the smart-home market
            share by 2023, estimates research firm IDC, with smart speakers and
            lighting equipment not far behind, at 15.4% and 11.8% respectively.
            There are already at least 7 billion connected IoT devices,
            according to market-research company IoT Analytics. But as
            smart-home technology becomes easier to use and its benefits become
            more clear, the industry is poised to take off. “Sustained growth is
            expected to continue … as consumers adopt multiple devices within
            their homes and as global availability of products and services
            increases,” according to IDC.",
          </p>
          <h3></h3>
          <p>
            Of course, as our homes learn more about us, keeping them secure
            will become all the more important. Every device that’s connected to
            the Internet is a potential target for hackers. When we’re talking
            about devices that can unlock our homes from afar, peer into our
            living rooms using cameras, and collect our most sensitive and
            personal data, cybersecurity will become all the more vital. Any
            kind of massive breach that turns off consumers, says Daniel Cooley,
            chief strategy officer at electronics-component manufacturer Silicon
            Labs, could be catastrophic for the industry. “I call it a
            mass-extinction event for the Internet of Things,” he says.
          </p>
          <h3></h3>
          <p>
            A range of technological developments will drive smart-home
            technology well beyond what’s available on store shelves today.
            Innovations in artificial intelligence, for example, stand to upend
            almost everything in our lives, including our homes. You might
            already be using some kind of AI-powered voice-assistant gadget to
            get the latest news or weather forecast every morning. But in the
            smart home of the future, those AI platforms could serve as the
            brain for entire homes, learning about residents and coordinating
            and automating all of their various smart gadgets. IoT company
            Crestron, for example, is working on software that tracks a person’s
            habits, like which music they want to hear in the morning or which
            lights they want to be on at a certain time of day. Then, once it
            gets the hang of a user’s preferences, it automatically plays just
            the right playlists or dims the lights before bedtime. “That’s
            really the next evolutionary step in true automation,” says John
            Clancy, head of Crestron’s residential business.
          </p>
          <h3></h3>
          <p>
            Robots, too, will have a role to play in the smart home of the
            future. Smart vacuum cleaners like iRobot’s Roomba are already
            picking up after us, while products like the Aibo, a robotic dog for
            children, show how they might help keep us company like a pet. As
            for the future? Robotic-furniture company Ori Living is working with
            Ikea on pieces that change based on your needs, getting the bed out
            of the way when you need a desk, or hiding your closet when it’s
            dinnertime. Design firm Design3 recently showed off a smart-home
            robot concept, CARL. The fabric-covered bot is meant to slowly roll
            around your home, activating its retractable cameras and sensors to
            detect intruders, notify you of any harmful emissions or keep an eye
            on your pet. And computer-graphics company Nvidia is working on a
            smart robotic arm that can act as its owner’s personal sous chef,
            doing everything from slicing and dicing veggies to helping with
            cleanup; it could be particularly useful for busy parents or
            disabled users. If such a device went into production, cameras and
            sensors could help prevent it from accidentally injuring an innocent
            bystander who’s just on the way to the fridge for a quick snack
            before dinnertime.
          </p>
          <h3></h3>
          <p>
            Health applications will drive at least some of the smart-home
            growth over the next decade. Cameras and sensors embedded in
            refrigerators will suggest more nutritious alternatives if people
            are reaching for the sugary sodas a little too frequently. Similar
            technology in medicine cabinets will check if residents have taken
            their prescriptions. And sensors will even show up in toilets to
            check for signs of any potential health conditions by scanning human
            waste before it’s flushed. Bathroom-fixture company Toto has
            experimented with urine-sampling toilets, while one company has
            filed patents for devices including a mirror that’s meant to monitor
            users’ health just by analyzing their skin. Homes will have health
            sensors of their own, too, that check for issues like water damage,
            pest infestation and so on, alerting owners to any potential
            problems before they become far costlier to manage.
          </p>
          <h3></h3>
          <p>
            All this learning and scanning that the smart home of the future
            will be doing may understandably raise privacy concerns. Indeed,
            some smart-home devices have already been targeted by hackers,
            whether to access the data they hold or to use them as tools in
            larger cybersecurity schemes. In 2016, hackers took over hundreds of
            thousands of insecure IoT devices, then used them to send bogus
            Internet traffic to target websites in hopes of crashing them; the
            incident temporarily crippled Internet connections throughout parts
            of North America and Europe. Government regulation is in the works
            too. A bill put forth by Virginia Senator Mark Warner in March would
            push the government to set up minimum security requirements for
            smart devices used by federal agencies; such requirements could
            eventually become standard for the industry at large.
          </p>
          <h3></h3>
          <p>
            You’re more likely than not to end up in a connected home one day,
            whether you mean to or not. Architect Michael Gardner, founder of
            construction firm Luxus Design Build, says homes are increasingly
            being built “smart” from the ground up. “It’s such an integral part
            of the home that we’re designing it from the beginning, where
            beforehand technology was always an afterthought,” he says.
            Ultimately, experts say, people will come to see smart-home
            technology as essential as electricity, refrigeration or
            air-conditioning. Smart-home tech, and the data it collects, will
            “be like plumbing,” says Cooley, from electronics-component
            manufacturer Silicon Labs. “You’ll rely on it.”
          </p>
          <h3></h3>
          <p className="font-italic">
            This appears in the August 05, 2019 issue of TIME.
          </p>
        </div>
      </div>
      <Contact />
    </div>
  );
};
