export const News = (props) => {
  return (
    <div id="news">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title text-center">
          <h2>News</h2>
          {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-md-3 col-sm-6 news">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="news-img" />
                    <div className="caption">
                      <h4>{d.title}</h4>
                      <p>{d.des}</p>
                      <a href={d.url}>Continue reading →</a>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
